import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'

import aivenLogo from '../../resources/images/Homepage/logos/aiven-crab.svg'

import green_arrow from '../../resources/images/pricing_page/arrow_pricing.svg'

import Spacing from '../../components/partials/Spacing'
import { navbarScrolling } from '../../helpers/navbarScrolling'
import { pricingContactBlogsSscript } from '../../helpers/utils/pricingContactBlogsSscript'

interface StateProps {
    pricingOptions: PricingState
}

interface PricingState {
    activeOption: 'haveKafka' | 'dontHaveKafka'
    showFeaturesKafka: boolean
}

const pricing: React.FC<StateProps> = () => {
    const data = useStaticQuery(graphql`
        query PricingSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Product_Pricing" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    const [pricingOptions, setPricingOptions] = useState<PricingState>({
        activeOption: 'haveKafka',
        showFeaturesKafka: false,
    })
    const { activeOption, showFeaturesKafka } = pricingOptions

    useEffect(() => {
        navbarScrolling()
        var url_string = window.location.href
        var url = new URL(url_string)
        var source = url.searchParams.get('s')

        if (source) {
            if (source === 'have-kafka') {
                setPricingOptions({
                    ...pricingOptions,
                    activeOption: 'haveKafka',
                })
            } else if (source === 'need-kafka')
                setPricingOptions({
                    ...pricingOptions,
                    activeOption: 'dontHaveKafka',
                })
        }
    }, [])

    const handleClick = (e) => {
        setPricingOptions({
            ...pricingOptions,
            activeOption: e.target.id,
        })
    }

    const showFeatures = (e) => {
        if (e.target.value === 'Kafka') {
            setPricingOptions({
                ...pricingOptions,
                showFeaturesKafka: true,
            })
        }
    }

    const hideFeatures = (e) => {
        if (e.target.value === 'Kafka') {
            setPricingOptions({
                ...pricingOptions,
                showFeaturesKafka: false,
            })
            document
                .getElementById('KafkaTable')
                .scrollIntoView({ behavior: 'auto', block: 'start' })
        }
    }

    useEffect(() => {
        pricingContactBlogsSscript()
    }, [])

    const haveKafkaTableMin = (
        <div className='table-responsive table-borderless'>
            <h3 className='pl-4 mb-2 f-20 ls-0 mt-3' id='KafkaTable'>
                Kafka
            </h3>

            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Topics
                        </th>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            SQL on Topic Data, Live queries
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Topics UI
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Topic Management
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr className='border'>
                        <td colSpan={4} className='text-center'>
                            <button
                                value='Kafka'
                                onClick={(e) => {
                                    showFeatures(e)
                                }}
                                className='primary-text border-0 outline-0 bg-white fw-600'>
                                Show all features {'\u2228'}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

    const haveKafkaTable = (
        <div className='table-responsive table-borderless'>
            <h3 className='pl-4 mb-2 f-20 ls-0 mt-3 pt-6' id='KafkaTable'>
                Kafka
            </h3>

            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Topics
                        </th>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            SQL on Topic Data, Live queries
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Topics UI
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Topic Management
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Partition & Replication Monitoring
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Metadata management
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Consumers
                        </th>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Consumer Lag Monitor
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Consumer Lag Metrics API (integrate Prometheus)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Consumer Lag Alerts
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Consumer Offset Management
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Connectivity
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Any Brokers / Cluster
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            JMX / Jolokia
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            PLAINTEXT
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            SASL_PLAINTEXT (GSSAPI/Kerberos)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            SASL_SSL (GSSAPI/Kerberos)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            SSL
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Integration
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Linked Kafka Connect Clusters
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Manage Any Kafka Connector
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Admin
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Kafka ACLs
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Kafka Quotas
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            3rd Party Services
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            AVRO Schema Registry
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='pl-4 mb-2 f-20 ls-0 mt-5'>Security, Compliance, Regulation</h3>

            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Authentication
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Basic Authentication
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            User Accounts
                        </th>
                        <td className='f-16'>5</td>
                        <td className='f-16'>20</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Service Accounts
                        </th>
                        <td className='f-16'>2</td>
                        <td className='f-16'>Unlimited</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Multiple Authentication Providers
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Auth Integrations (LDAP, AD, SAML/SSO, Azure, Okta, Kerberos, Custom)
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            authorization
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            RBAC
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Custom Permission Groups
                        </th>
                        <td className='f-16'>2</td>
                        <td className='f-16'>Unlimited</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Data Namespace
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            compliance
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Audit Log
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Data Masking Field Policies
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Track PII data usage
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='pl-4 mb-2 f-20 ls-0 mt-5'>Data</h3>

            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            SQL
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Bounded Queries, Live Sampling
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Unbounded Queries (Scalable processors)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Data API for SQL
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            SQL CLI
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Views
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            UI Tree / Table
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            View & Download Raw Data
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            CRUD
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            UI / CLI / SQL
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Data Type
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Primitives, JSON, XML, CSV
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            AVRO (SR Integration)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Protobuf / Custom Serializer
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Field Level Policies
                        </th>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Masking
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='pl-4 mb-2 f-20 ls-0 mt-5'>Data Flows, Streaming Apps, Integration</h3>

            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Flow Types
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            SQL Processors
                        </th>
                        <td className='f-16'>10</td>
                        <td className='f-16'>Unlimited</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Kafka Connectors
                        </th>
                        <td className='f-16'>Unlimited</td>
                        <td className='f-16'>Unlimited</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Linked Apps
                        </th>
                        <td className='f-16'>Unlimited</td>
                        <td className='f-16'>Unlimited</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Deployment Options / Scale
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Kafka Connect
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Kubernetes Integration
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            GitOps / CLI
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Monitor & Alerts
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            App status & Throughput Metrics
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            App Topology
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Threshold Alerts (consumers)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            JMX Exporters Integration for Prometheus
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='pl-4 mb-2 f-20 ls-0 mt-5'>Monitoring & Alerting</h3>

            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Services
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Brokers
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Zookeeper
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Connect Workers
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Schema Registry
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Metrics
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Topic
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Connector
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Processor
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            App
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Integration metrics
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Prometheus (Custom JMX Exporters)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Grafana (Curated Templates)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Alerts
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Infrastructure
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Topics
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Consumer Alerts
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Alert Integrations
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Max no. integrations
                        </th>
                        <td>2</td>
                        <td>Unlimited</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Slack
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            PagerDuty
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            DataDog
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            CloudWatch
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Alert Manager
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Webhook
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Custom (on demand)
                        </th>
                        <td className='f-40'>-</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='pl-4 mb-2 f-20 ls-0 mt-5'>Support Services</h3>
            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Support Level
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            Support Coverage
                        </th>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Office Hours (8x5 in customer&apos;s timezone)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            24/7
                        </th>
                        <td className='light-blue'>Optional</td>
                        <td className='light-blue'>Optional</td>
                    </tr>
                    <tr className='border'>
                        <td colSpan={4} className='text-center'>
                            <a
                                className='primary-text border-0 outline-0 bg-white fw-600'
                                href='/legals/sla/'>
                                More on Support &rarr;
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3 className='pl-4 mb-2 f-20 ls-0 mt-5'>Deployment Options</h3>
            <table className='table table-responsive table-pricing'>
                <thead>
                    <tr className='border-pricing-team '>
                        <th
                            className='border-right border-pricing-team pt-4 pl-4 footer-color '
                            scope='col'>
                            Features
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Team
                        </th>
                        <th
                            className='border-right border-pricing-team pt-4 text-center footer-color '
                            scope='col'>
                            Platform
                        </th>
                    </tr>
                </thead>
                <tbody className='border-pricing-table'>
                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            on-premises
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            Linux (.tar.gz)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Docker
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Kubernetes Helm
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            All Clouds
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            cloud marketplace
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            AWS
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Azure
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Google (coming soon)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            IBM (coming soon)
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>

                    <tr>
                        <th
                            className='pl-4 f-16 lh-30 light-blue text-uppercase fw-500'
                            scope='row'>
                            managed cloud & compatibility
                        </th>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <th className='pl-4' scope='row'>
                            AWS MSK
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Azure HDInsight
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Confluent Cloud
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Aiven
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Cloudera
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Kafka Vanilla
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            IBM Event Streams
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr>
                        <th className='pl-4' scope='row'>
                            Red Panda
                        </th>
                        <td className='light-blue'>&#10004;</td>
                        <td className='light-blue'>&#10004;</td>
                    </tr>
                    <tr className='border'>
                        <td colSpan={4} className='text-center'>
                            <button
                                value='Kafka'
                                onClick={(e) => {
                                    hideFeatures(e)
                                }}
                                className='primary-text border-0 outline-0 bg-white fw-600'>
                                Hide all features {'\u005E'}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

    return (
        <Layout newsletter={false} linkColor={'dark'} isTransparent={true}>
            <HeaderSEO pageSEO={pageSEO} />
            <section>
                <div className='hero-margin container-1'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-sm-5 col-12 text-center'>
                            <h1 className='h2-like'>Start small, grow enterprise</h1>
                        </div>
                        <div className='col-sm-8 col-10 text-center'>
                            <p>
                                Complete DataOps for Kafka teams. No data restrictions, unlimited
                                Kafka brokers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Spacing />
            <section>
                <div className='container-1'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='border-light-blue d-flex flex-wrap justify-content-center rounded25 h-25 offset-lg-2 mobile-margin-left-half mobile-margin-right-half'>
                            <a
                                className={
                                    activeOption === 'haveKafka'
                                        ? 'pricing-button-active cursor-pointer'
                                        : 'pricing-button-inactive cursor-pointer'
                                }
                                id='haveKafka'
                                onClick={(e) => handleClick(e)}>
                                I already have Kafka
                            </a>
                            <a
                                style={{ position: 'relative' }}
                                className={
                                    activeOption === 'dontHaveKafka'
                                        ? 'pricing-button-active cursor-pointer'
                                        : 'pricing-button-inactive cursor-pointer'
                                }
                                id='dontHaveKafka'
                                onClick={(e) => handleClick(e)}>
                                I need a Kafka
                                <img
                                    className='green-arrow  d-md-block d-none'
                                    src={green_arrow}
                                    alt='Arrow'
                                />
                            </a>
                        </div>
                        <div className='ml-4 w-15 green-pricing d-md-block d-none'>
                            Claim $350 free Managed Kafka credits
                        </div>
                    </div>

                    {activeOption === 'haveKafka' ? (
                        <div className='text-center f-18 fw-400 mt-5 light-blue mb-5'>
                            Bring your self-managed or Managed Kafka, add DataOps on a yearly
                            subscription.
                        </div>
                    ) : (
                        <div className='text-center f-18 fw-400 mt-5 light-blue mb-5'>
                            Self-contained Kafka and supporting infrastructure as a localhost or dev
                            Kafka environment
                        </div>
                    )}
                </div>
            </section>

            <section>
                <div className='container-1'>
                    {activeOption === 'haveKafka' ? (
                        <div className='d-flex flex-wrap justify-content-center'>
                            <div className='col-md-4 mx-4 mobile-margin-left-half mt-2 col-10 col-sm-11 border-pricing-team p-4 pricing-team-color rounded25 mobile-margin-top'>
                                <h2 className='text-center pb-0'>Team</h2>
                                <div className=' mb-3'>
                                    <div className='roll-button mt-2 pt-1 mb-4 text-center pb-4'>
                                        <p className='cera-pro d-flex align-items-center justify-content-center'>
                                            <span className='f-48'>$4,500</span>
                                            <span className='f-14 fw-600 pl-2'>
                                                per Kafka cluster per year
                                            </span>
                                        </p>
                                        <a
                                            className='primary-text border-red mr-0 f-14'
                                            href='/contact-us/?s=quote-developer-team'>
                                            <span data-hover='Buy now'>Buy now</span>
                                        </a>
                                    </div>
                                    <div className='text-center'>
                                        <p className='f-16'>
                                            <b>Designed for teams maturing their Kafka adoption</b>
                                        </p>
                                        <div className='d-flex justify-content-center'>
                                            <ul className='blue-checked-list pl-0 pl-0'>
                                                <li>
                                                    <div className='mr-auto d-inline'>
                                                        Focuses on increasing productivity
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mx-4 mobile-margin-left-half mt-2 col-10 col-sm-11 border-pricing-team p-4 rounded25 mobile-margin-top'>
                                <h2 className='text-center pb-0'>Platform </h2>
                                <div className=' mb-3'>
                                    <div className='roll-button mt-5 mb-4 text-center py-4'>
                                        <a
                                            className='primary-text border-red mr-0 f-14'
                                            href='/contact-us/?s=quote-platform-team'>
                                            <span data-hover='Request quote'>Request quote</span>
                                        </a>
                                    </div>
                                    <div className='text-center'>
                                        <p className='f-16'>
                                            <b>
                                                Designed for teams seeking enterprise-wide Kafka
                                                adoption
                                            </b>
                                        </p>
                                        <div className='d-flex justify-content-center'>
                                            <ul className='blue-checked-list pl-0 pl-0'>
                                                <li>
                                                    <div className='mr-auto d-inline'>
                                                        Protects with enterprise security &
                                                        governance
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='d-flex flex-wrap justify-content-center'>
                            <Fragment>
                                <div className='col-md-4 mobile-margin-left-half mt-4 col-10 border-pricing-team p-4 rounded25 mr-3 position-relative'>
                                    <div className='pricing-green-label-rounded'>Free Dev env</div>
                                    <h2 className='text-center pb-0'>Developer Box </h2>
                                    <div className='mb-3'>
                                        <div className='roll-button my-4 text-center'>
                                            <a
                                                className='primary-text mr-0 border-red f-14'
                                                href='/apache-kafka-docker/'>
                                                <span data-hover='Docker pull'>Docker pull</span>
                                            </a>
                                        </div>
                                        <ul className='blue-checked-list pl-0'>
                                            <li>
                                                <span>
                                                    Docker of Kafka, Schema Reg, Connect &
                                                    Elasticsearch
                                                </span>
                                            </li>
                                            <li>
                                                <span>Packaged with free Lenses.io instance</span>
                                            </li>
                                            <li>
                                                <span>
                                                    Perfect localhost or dev Kafka environment{' '}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='offset-md-1 col-md-4 mt-4 mobile-margin-left-half col-10 border-pricing-team p-4 rounded25 mr-3 position-relative footer pricing-team-color'>
                                    <div className='pricing-green-label-aiven'>
                                        <img
                                            className='img-fluid w-17-5 mr-3'
                                            src={aivenLogo}
                                            alt='Aiven'
                                        />
                                        Free credits{' '}
                                    </div>
                                    <h2 className='text-center pb-0'>Fully managed Kafka </h2>
                                    <div className='mb-3'>
                                        <div className='roll-button my-4 text-center'>
                                            <a
                                                className='primary-text mr-0 border-red f-14'
                                                href='/contact-us/?s=aiven-discount'>
                                                <span data-hover='Start trial'>Start trial</span>
                                            </a>
                                        </div>
                                        <ul className='blue-checked-list pl-0'>
                                            <li>
                                                <span>$350 of free managed Kafka + Lenses</span>
                                            </li>
                                            <li>
                                                <span>Deploy to the cloud of your choice</span>
                                            </li>
                                            <li>
                                                <span>Self-service infra & data pipelines</span>
                                            </li>
                                        </ul>
                                        <a href='/cloud/aiven' className='home-scroll-link'>
                                            Learn more <i className='blue-pointer-right-home'></i>
                                        </a>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    )}
                </div>
            </section>

            <div className='container-1 mt-5'>
                <div className='mb-5 text-center'>
                    <a className='primary-text ' href='/kafka-roi-calculator/'>
                        Not sure? Check out our Kafka ROI calculator{' '}
                        <i className='red-pointer-right'></i>
                    </a>
                </div>

                <div className='text-center'>
                    <a
                        href='https://www.g2.com/products/lenses/reviews?utm_source=review-widget'
                        title='Read reviews of Lenses on G2'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                            src='https://www.g2.com/products/lenses/widgets/stars?color=gray&amp;type=read'
                            className='w-100 rounded'
                            style={{ maxWidth: '200px' }}
                            alt='Read Lenses reviews on G2'
                        />
                    </a>
                </div>
            </div>

            <section>
                {activeOption == 'haveKafka' && (
                    <div className='container-1'>
                        <div className='my-3'></div>
                        {showFeaturesKafka ? haveKafkaTable : haveKafkaTableMin}
                    </div>
                )}
                <Spacing />
            </section>
        </Layout>
    )
}

export default pricing
